












import Vue from "vue";
import router from "./router";
import firebase from "./firebase";

async function navigate(path: string): Promise<void> {
  try {
    await router.push(path);
  } catch (err) {
    console.log("Failed to navigate: ", err);
  }
}

export default Vue.extend({
  router,
  methods: {
    contacts: async function() {
      await navigate("/contacts");
    },
    phoneNumbers: async function() {
      await navigate("/phone-numbers");
    },
    logout: async function() {
      firebase.auth().signOut();
      await navigate("/logon");
    }
  }
});
