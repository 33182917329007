


















import Api from "@/Api";
import Vue from "vue";

import Calendar from "../components/Calendar.vue";

interface Data {
  activeTabIndex: number;
  phoneNumbers: { number: string; description: string }[];
  loading: boolean;
}

export default Vue.extend({
  data: function(): Data {
    return {
      activeTabIndex: 0,
      phoneNumbers: [],
      loading: true
    };
  },
  mounted: async function() {
    try {
      const phoneNumbers = await Api.getPhoneNumbers();
      this.phoneNumbers = phoneNumbers.data.data ? phoneNumbers.data.data : [];
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },
  components: { Calendar }
});
