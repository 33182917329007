






















import { BvTableFieldArray } from "bootstrap-vue";
import Vue from "vue";
import Api from "../Api";
import NewContactModal from "../components/NewContactModal.vue";

interface ContactTableRow {
  name: string;
  phoneNumber: string;
  buttons: null;
}

interface Data {
  loading: boolean;
  errorText: string;
  contacts: ContactTableRow[];
  fields: BvTableFieldArray;
}

export default Vue.extend({
  data: function(): Data {
    return {
      loading: true,
      errorText: "",
      contacts: [],
      fields: [
        { key: "name", label: "Naam" },
        { key: "phoneNumber", label: "Telefoon" },
        { key: "buttons", label: "" }
      ]
    };
  },
  methods: {
    newContact: function() {
      this.$bvModal.show("new-contact-modal");
    },
    contactCreated: function() {
      this.reload();
    },
    contactCreationError: function(error: string) {
      this.errorText = error;
    },
    reload: async function() {
      try {
        this.errorText = "";
        const response = await Api.getContacts();
        this.contacts = [];
        if (response.data.data) {
          for (const apiContact of response.data.data) {
            const contactRow: ContactTableRow = {
              name: apiContact.name,
              phoneNumber: apiContact.phoneNumber,
              buttons: null
            };
            this.contacts.push(contactRow);
          }
        }
      } catch (error) {
        this.errorText =
          "Er ging iets mist tijdens het laden van de contact personen";
        console.error(error);
      }
      this.loading = false;
    },
    deleteContact: async function(phoneNumber: string) {
      try {
        await Api.deleteContact(phoneNumber);
      } catch (error) {
        this.errorText =
          "Er ging iets mist tijdens het verwijderen van de contact personen";
        console.error(error);
      }
      this.reload();
    }
  },
  mounted: function() {
    this.reload();
  },
  components: {
    NewContactModal
  }
});
