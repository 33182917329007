













import Vue from "vue";
import Component from "vue-class-component";

import Api from "../Api";

const Props = Vue.extend({
  props: {
    phoneNumber: String,
    editEntry: Object
  }
});

interface ContactOption {
  value: string;
  text: string;
}

@Component
export default class NewCalendarEntryModal extends Props {
  contactOptions: ContactOption[] = [];
  errorText = "";

  // get startDate(): string {
  //   if (!this.editEntry.start) return "";
  //   returnthis.editEntry.start).toISOString();
  // }

  // set startDate(value: string) {
  //   this.editEntry.start = Dayjs(value)
  //     .startOf("day")
  //     .format("YYYY-MM-DD");
  // }

  // get endDate(): string {
  //   return Dayjs(this.editEntry.end).format("YYYY-MM-DD");
  // }

  // set endDate(value: string) {
  //   this.editEntry.end = Dayjs(value)
  //     .startOf("day")
  //     .format("YYYY-MM-DD");
  // }

  async onSubmit(event: any) {
    console.log("This is submitting");
    try {
      this.errorText = "";

      const dateMissing = !this.editEntry.start || !this.editEntry.start;
      if (!this.editEntry.isDefault && dateMissing) {
        this.errorText = "Datum ontbreekt";
        event.preventDefault();
        return;
      } else if (!this.editEntry.isDefault) {
        const start = new Date(this.editEntry.start);
        const end = new Date(this.editEntry.end);
        console.log("Start:", start, "End:", end);
        if (start >= end) {
          this.errorText = "Start datum valt na eind datum";
          event.preventDefault();
          return;
        }
      }

      let contactName = "";
      for (const contactOption of this.contactOptions) {
        if (contactOption.value === this.editEntry.contactPhoneNumber) {
          contactName = contactOption.text;
          break;
        }
      }
      if (!this.editEntry.contactPhoneNumber || contactName === "") {
        this.errorText = "Geen contact geselecteerd";
        event.preventDefault();
        return;
      }

      const apiCall = this.editEntry.id
        ? Api.updateCalendarEntry
        : Api.createCalendarEntry;

      await apiCall(this.phoneNumber, {
        id: this.editEntry.id,
        start:
          this.editEntry.isDefault || this.editEntry.start === undefined
            ? undefined
            : this.editEntry.start,
        end:
          this.editEntry.isDefault || this.editEntry.end === undefined
            ? undefined
            : this.editEntry.end,
        contactName,
        contactPhoneNumber: this.editEntry.contactPhoneNumber
      });

      this.$emit("submitted");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        this.errorText = error.response.data.error;
      } else {
        this.errorText = "Er ging iets mis";
      }
    }
  }

  async mounted() {
    this.contactOptions = [];
    const contactsResp = await Api.getContacts();
    if (contactsResp.data && contactsResp.data.data) {
      for (const apiContact of contactsResp.data.data) {
        this.contactOptions.push({
          value: apiContact.phoneNumber,
          text: apiContact.name
        });
      }
    }
  }

  show() {
    (this.$refs["the-modal"] as any).show();
  }
}
