
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCD1ep7UmdHSzyhGdLLlp3QqwX8VvRv7Yk",
    authDomain: "permanentie-316f6.firebaseapp.com",
    projectId: "permanentie-316f6",
    storageBucket: "permanentie-316f6.appspot.com",
    messagingSenderId: "838081369013",
    appId: "1:838081369013:web:aebb1559abf8eab7eebffc",
    measurementId: "G-7T5FWBPX65"
  };
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function getCurrentUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
}

function getAuthHeader(): Promise<string> {
    return new Promise((resolve, reject) => {
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) {
            reject("No active user");
            return;
        } else {
            currentUser.getIdToken(true).then(function(idToken) {
                resolve(idToken);
                return;
            }).catch(function(error) {
                reject(error);
            });
        }
    });
}

export default firebase;

export {getCurrentUser, getAuthHeader};