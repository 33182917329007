










import Vue from "vue";
import Api from "../Api";

interface Data {
  name: string;
  phoneNumber: string;
}

export default Vue.extend({
  data: function(): Data {
    return {
      name: "",
      phoneNumber: ""
    };
  },
  mounted: function() {
    this.$root.$on("bv::modal::show", (_bvEvent: any, modalId: string) => {
      if (modalId === "new-contact-modal") {
        this.onReset();
      }
    });
  },
  methods: {
    onSubmit: async function() {
      try {
        await Api.createContact({
          name: this.name,
          phoneNumber: this.phoneNumber
        });

        this.$emit("submitted");
      } catch (error) {
        let errorText: string;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorText = error.response.data.error;
        } else {
          errorText = "Er ging iets mis";
        }
        this.$emit("error", errorText);
      }
    },
    onReset: function() {
      this.name = "";
      this.phoneNumber = "";
    }
  }
});
