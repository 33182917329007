import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import './firebase.ts'
import App from "./App.vue";


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


new Vue({
  render: h => h(App),
}).$mount("#app");
