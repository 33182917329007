















import Vue from "vue";
import firebase from "../firebase";

export default Vue.extend({
  data: function() {
    return {
      email: "",
      password: "",
      errorText: ""
    };
  },
  methods: {
    onSubmit: function(event: Event) {
      event.preventDefault();
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.errorText = "";
          this.$router.push("/");
        })
        .catch(error => {
          switch (error.code) {
            case "auth/wrong-password":
            case "auth/user-not-found":
            case "auth/invalid-email":
              this.errorText = "Gebruikersnaam of paswoord ongeldig.";
              break;
            case "auth/user-disabled":
              this.errorText = "Gebruiker vergrendeld";
              break;
          }
        });
    }
  }
});
