




import Vue from "vue";

function timeFormatter(time: string) {
  return time.substring(0, 5);
}

export default Vue.extend({
  props: {
    contactAssignments: Array,
    phoneNumber: String
  },
  data: function() {
    return {
      fields: [
        { key: "startTime", label: "Begin", formatter: timeFormatter },
        { key: "endTime", label: "Einde", formatter: timeFormatter },
        { key: "contactName", label: "Naam" },
        { key: "contactPhoneNumber", label: "Telefoon" }
      ]
    };
  },
  methods: {
    deleteLine: function(id: string) {
      this.$emit("deleteScheduleEntry", id);
    },
    entryCreated: function() {
      this.$emit("entryCreated");
    },
    entryCreationError: function(error: string) {
      console.log("Creation error:", error);
    }
  }
});
