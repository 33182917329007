import VueRouter, {RouteConfig, NavigationGuard} from "vue-router";
import Contacts  from "./pages/Contacts.vue";
import PhoneNumbers from "./pages/PhoneNumbers.vue";
import Logon from "./pages/Logon.vue";
import Tos from "./pages/Tos.vue";
import Privacy from "./pages/Privacy.vue";
import {getCurrentUser} from "./firebase";

const requireAuth:NavigationGuard = function requireAuth(_to, _from, next) {
    getCurrentUser().then((user) => {
        if (user) {
            next();
        }else {
            next("/logon");
        }
    });
}

const routes: RouteConfig[]  = [
    {path: '/', redirect: '/phone-numbers'},
    {path: '/contacts', component: Contacts, beforeEnter: requireAuth},
    {path: '/phone-numbers', component: PhoneNumbers, beforeEnter: requireAuth},
    {path: '/logon', component: Logon},
    {path: '/tos', component: Tos},
    {path: '/privacy', component: Privacy}
];

const router = new VueRouter({routes});

export default router;