











































import Api from "../Api";
import Vue from "vue";
import CalendarEntry from "./CalendarEntry.vue";
import NewCalendarEntryModal from "./NewCalendarEntryModal.vue";
import Dayjs from "dayjs";

interface ContactAssignment {
  index: number;
  contactName?: string;
  contactPhoneNumber: string;
  startTime: string;
  endTime: string;
}

interface EditEntryData {
  id?: string;
  isDefault: boolean;
  start?: string;
  end?: string;
}

interface EntryData {
  id: string;
  start?: string;
  end?: string;
  contactName: string;
  contactPhoneNumber: string;
}

interface CalendarData {
  activeTabIndex: number;
  loading: boolean;
  entries: EntryData[];
  entryInEdit: EditEntryData;
  fields: any[];
}

function clearCalendar(calendar: CalendarData) {
  calendar.entries = [];
}

const displayDateTimeFormat = new Intl.DateTimeFormat("nl-NL", {
  timeZone: "Europe/Brussels",
  // @ts-ignore
  dateStyle: "short",
  timeStyle: "short"
});

function displayTimeFormatter(time?: string) {
  return time === undefined || time === null || time === ""
    ? ""
    : displayDateTimeFormat.format(new Date(time));
}

function dateToInputString(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export default Vue.extend({
  props: {
    phoneNumber: String,
    description: String,
  },
  data: function (): CalendarData {
    return {
      activeTabIndex: 0,
      loading: true,
      entries: [],
      entryInEdit: {
        isDefault: true,
      },
      fields: [
        { key: "buttons", label: "" },
        { key: "contactName", label: "Naam" },
        { key: "start", label: "Begin", formatter: displayTimeFormatter },
        { key: "end", label: "Einde", formatter: displayTimeFormatter },
        { key: "contactPhoneNumber", label: "Telefoon" },
      ],
    };
  },
  mounted: async function () {
    await this.reload();
  },
  methods: {
    clickEntryTab(newTabIndex: number, _prevTabIndex: number, bvEvent: any) {
      if (newTabIndex == this.entries.length) {
        bvEvent.preventDefault();
      }
    },
    createDefaultEntry() {
      this.entryInEdit = {
        isDefault: true,
      };
      (this.$refs["calendar-entry-modal"] as any).show();
    },
    addEntry: function() {
      const newDate = new Date();
      console.log("newDate: ", newDate);
      this.entryInEdit = {
        isDefault: false,
        start: dateToInputString(Dayjs(newDate).startOf("day").toDate()),
        end: dateToInputString(Dayjs(newDate).endOf("day").toDate()),
      };
      (this.$refs["calendar-entry-modal"] as any).show();
    },
    // editEntry: function(entryToEdit: EntryData) {
    //   this.entryInEdit = {
    //     ...entryToEdit,
    //     isDefault: !entryToEdit.start && !entryToEdit.end,
    //     errorText: ""
    //   };
    //   (this.$refs["calendar-entry-modal"] as any).show();
    // },
    // copyEntry: function(entrToCopy: EntryData) {
    //   this.entryInEdit = {
    //     ...entrToCopy,
    //     id: undefined,
    //     isDefault: false,
    //     errorText: "",
    //     start: "",
    //     end: ""
    //   };
    //   (this.$refs["calendar-entry-modal"] as any).show();
    // },
    reload: async function () {
      this.loading = true;
      const phoneNumber = await Api.getPhoneNumber(this.phoneNumber);
      try {
        if (phoneNumber.data.data) {
          const data = phoneNumber.data.data;

          clearCalendar(this);

          if (data.calendarEntries) {
            for (const entry of data.calendarEntries) {
              this.entries.push({
                id: entry.id === undefined ? "no-id" : entry.id,
                start: entry.start,
                end: entry.end,
                contactName: entry.contactName,
                contactPhoneNumber: entry.contactPhoneNumber,
                // title: formatTitle(entry.start, entry.end),
                // contactAssignments:
                //   entry.contactAssignments != undefined
                //     ? entry.contactAssignments.map((apiAssignment, i) => {
                //         return {
                //           ...apiAssignment,
                //           index: i
                //         };
                //       })
                //     : []
              });
            }
          }
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    deleteEntry: async function (entry: EntryData) {
      console.log("deleting: ", entry);
      try {
        await Api.deleteCalendarEntry(this.phoneNumber, entry.id);
      } catch (error) {
        console.error(error);
      }
      this.reload();
    },
    entryCreated: function () {
      this.reload();
    },
  },
  components: { CalendarEntry, NewCalendarEntryModal },
});
