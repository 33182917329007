import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAuthHeader } from "@/firebase";

axios.defaults.baseURL = "https://permanentie-p25s3w7epa-ew.a.run.app/api";
// axios.defaults.baseURL = "http://localhost:8081/api";
axios.interceptors.request.use(async function(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const token = await getAuthHeader();
  config.headers.Authorization = token;
  return config;
});

interface Response<T> {
  data?: T;
  error?: string;
  warning?: string;
}

interface ApiContact {
  name: string;
  phoneNumber: string;
}

interface ApiCalendarEntry {
  id?: string;
  start?: string;
  end?: string;
  // contactAssignments: ApiContactAssignment[]
  contactName: string;
  contactPhoneNumber: string;
}

interface ApiContactAssignment {
  contactName?: string;
  contactPhoneNumber: string;
  startTime: string;
  endTime: string;
}

interface ApiPhoneNumber {
  number: string;
  calendarEntries: ApiCalendarEntry[];
}

interface ApiPhoneNumberLite {
  number: string;
  description: string;
}

export default {
  getContacts: async function(): Promise<
    AxiosResponse<Response<ApiContact[]>>
  > {
    return axios.get<Response<ApiContact[]>>(`/contacts`);
  },
  createContact: async function(
    contact: ApiContact
  ): Promise<AxiosResponse<Response<ApiContact>>> {
    return axios.post<Response<ApiContact>>(`/contacts`, contact);
  },
  deleteContact: async function(phoneNumber: string): Promise<AxiosResponse> {
    return axios.delete(`/contacts/${encodeURIComponent(phoneNumber)}`);
  },
  getPhoneNumbers: async function(): Promise<
    AxiosResponse<Response<ApiPhoneNumberLite[]>>
  > {
    return axios.get(`/phone-numbers`);
  },
  getPhoneNumber: async function(
    phoneNumber: string
  ): Promise<AxiosResponse<Response<ApiPhoneNumber>>> {
    return axios.get(`/phone-numbers/${encodeURIComponent(phoneNumber)}`);
  },
  deleteCalendarEntry: async function(
    phoneNumber: string,
    calendarEntryId: string
  ): Promise<AxiosResponse> {
    return axios.delete(
      `/phone-numbers/${encodeURIComponent(
        phoneNumber
      )}/calendar/${calendarEntryId}`
    );
  },
  createCalendarEntry: async function(
    phoneNumber: string,
    entry: ApiCalendarEntry
  ): Promise<AxiosResponse> {
    return axios.post(
      `/phone-numbers/${encodeURIComponent(phoneNumber)}/calendar`,
      entry
    );
  },
  updateCalendarEntry: async function(
    phoneNumber: string,
    entry: ApiCalendarEntry
  ): Promise<AxiosResponse> {
    return axios.put(
      `/phone-numbers/${encodeURIComponent(phoneNumber)}/calendar/${entry.id}`,
      entry
    );
  }
};
